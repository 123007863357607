import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SnackbarContext } from './SnackbarProvider';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Snackbar() {
  const matches = useMediaQuery('(min-width:600px)');

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    anchorOriginTopCenter: {
      top: !matches ? '67px' : '77px',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="wrapper">
      <SnackbarContext.Consumer>
        {({ open, setOpen, props }) => (
          <MuiSnackbar
            open={open}
            // autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={(_e) => setOpen(false)}
            className={classes.anchorOriginTopCenter}
          >
            <Alert onClose={(_e) => setOpen(false)} severity="info" {...props} data-testid="alert">
              <span data-testid="message">{props?.message}</span>
            </Alert>
          </MuiSnackbar>
        )}
      </SnackbarContext.Consumer>
    </div>
  );
}
