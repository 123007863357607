/* eslint-disable default-case, consistent-return */
export interface IGetTimeDiff {
  firstDate: Date;
  secondDate: Date;
  format?: 'days' | 'hours' | 'minutes' | 'seconds' | 'miliseconds';
}

export const getTimeDiff = ({ firstDate, secondDate, format = 'minutes' }: IGetTimeDiff) => {
  const diffMs = firstDate.getTime() - secondDate.getTime();

  switch (format) {
    case 'days':
      return Math.floor(diffMs / (1000 * 60 * 60 * 24));
    case 'hours':
      return Math.floor(diffMs / (1000 * 60 * 60));
    case 'minutes':
      return Math.floor(diffMs / (1000 * 60));
    case 'seconds':
      return Math.floor(diffMs / 1000);
    case 'miliseconds':
      return Math.floor(diffMs);
  }
};
