import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectOptions, TwilioError } from 'twilio-video';
import AppStateProvider, { useAppState } from '@state';
import ErrorDialog from '@components/ErrorDialog';
import LoginPage from '@components/LoginPage';
import PrivateRoute from '@components/PrivateRoute';
import { VideoProvider } from '@components/VideoProvider';
import Snackbar from '@components/Snackbar';
import SnackbarProvider from '@components/Snackbar/SnackbarProvider';
import ThemeProvider, { ThemeContext } from '@context/ThemeProvider';
import muiTheme from './theme';
import '@types';
import App from './App';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  // bandwidthProfile: { // https://www.twilio.com/docs/video/tutorials/understanding-video-rooms#comparing-room-types does not allowed on p2p connection, we need p2p connections for E2E encryption
  //   video: {
  //     mode: 'collaboration', // https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#BandwidthProfileMode
  //     renderDimensions: { // https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#VideoRenderDimensions
  //       high: { height: 1080, width: 1920 },
  //       standard: { height: 720, width: 1280 },
  //       low: { height: 176, width: 144 },
  //     },
  //   },
  // },
  // dominantSpeaker: false, // does not allowed on p2p connection
  video: { height: 720, frameRate: 24, width: 1280 },
  maxAudioBitrate: 16000, // When the audio is human speech, we may restrict maxAudioBitrate to 16Kbps to save bandwidth without any significant quality degradation. For music remove this line
  // networkQuality: { local: 1, remote: 1 }, // does not allowed on p2p connection
  // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }], // does not allowed on p2p connection
};

const VideoApp = () => {
  const { error, setError, timeout } = useAppState();

  const onErrorCallback = (err: TwilioError) => {
    window.clearTimeout(timeout);
    setError(err);
  };

  return (
    <VideoProvider options={connectionOptions} onError={onErrorCallback}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <ThemeProvider>
    <ThemeContext.Consumer>
      {({ theme }) => (
        <MuiThemeProvider theme={muiTheme(theme)}>
          <SnackbarProvider>
            <Snackbar />
            <CssBaseline />
            <Router>
              <AppStateProvider>
                <Switch>
                  <PrivateRoute exact path="/">
                    <VideoApp />
                  </PrivateRoute>
                  <PrivateRoute path="/room/:URLRoomName">
                    <VideoApp />
                  </PrivateRoute>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </AppStateProvider>
            </Router>
          </SnackbarProvider>
        </MuiThemeProvider>
      )}
    </ThemeContext.Consumer>
  </ThemeProvider>,
  document.getElementById('root'),
);
