import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
  }
}

export default (type: 'light' | 'dark' | undefined) =>
  createMuiTheme({
    palette: {
      type,
      primary: {
        main: '#6bdfd6',
      },
      secondary: {
        main: '#6bdfd6',
      },
    },
    sidebarWidth: 260,
    sidebarMobileHeight: 90,
  });
