import React, { createContext, ReactNode, useState } from 'react';

type Theme = 'light' | 'dark' | undefined;

export interface IThemeContext {
  theme: Theme;
  setTheme: (state: Theme) => void;
}

export const ThemeContext = createContext<IThemeContext>(null!);

interface ThemeProviderProps {
  children: ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setThemeState] = useState('light' as Theme);

  const setTheme = (t: Theme) => {
    setThemeState(t);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
