import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';

export default function LocalAudioLevelIndicator() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;

  return <AudioLevelIndicator size={30} audioTrack={audioTrack} background="#6f6e6f" />;
}
