import React, { createContext, ReactNode, useState } from 'react';
import { AlertProps } from '@material-ui/lab/Alert';

interface IProps extends AlertProps {
  message?: string;
}

export interface ISnackbarContext {
  open: boolean;
  setOpen: (state: boolean) => void;
  props: IProps;
  setProps: (props: IProps) => void;
}

export const SnackbarContext = createContext<ISnackbarContext>(null!);

interface SnackbarProviderProps {
  children: ReactNode;
}

export default function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpenState] = useState(false);
  const [props, setPropsState] = useState({} as IProps);

  const setOpen = (state: boolean) => {
    setOpenState(state);
  };

  const setProps = (p: IProps) => {
    setPropsState(p);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        setOpen,
        props,
        setProps,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
