import { useContext } from 'react';
import { SnackbarContext } from '@components/Snackbar/SnackbarProvider';

export default function useSnackbarContext() {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbarContext must be used within a SnackbarProvider');
  }

  return context;
}
