import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Brightness7 from '@material-ui/icons/Brightness7';
import Brightness4 from '@material-ui/icons/Brightness4';
import Tooltip from '@material-ui/core/Tooltip';

import useThemeContext from '@hooks/useThemeContext';

export default function ToggleThemeButton() {
  const { theme, setTheme } = useThemeContext();

  return (
    <Tooltip
      title={theme === 'dark' ? 'Switch to Light Theme' : 'Switch to Dark Theme'}
      placement="bottom"
      PopperProps={{ disablePortal: true }}
    >
      <IconButton
        color="default"
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        data-cy-theme-toggle
      >
        {theme === 'dark' ? <Brightness7 /> : <Brightness4 />}
      </IconButton>
    </Tooltip>
  );
}
