import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import SanasearchLogo from 'assets/sanasearch-white-logo.svg';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import Timer from 'react-compound-timer';
import LocalAudioLevelIndicator from './LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import ToggleThemeButton from './ToggleThemeButton';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
      [theme.breakpoints.up('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
    roomName: {
      color: theme.palette.text.primary,
    },
    logo: {
      [theme.breakpoints.up('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          width: '50px',
          height: '40px',
        },
      },
      '&.isConnected': {
        display: 'block',
      },
    },
    timer: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
  }),
);

export default function MenuBar() {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`/room/${roomName}${window.location.search || ''}`),
      );
    }
    getToken(name, roomName).then((token) => connect(token));
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <a href="https://www.sanasearch.ch/" target="_blank" rel="noreferrer noopener">
          <ReactSVG
            src={SanasearchLogo}
            className={clsx(classes.logo, { isConnected: roomState === 'connected' })}
          />
        </a>
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                label="Your Name"
                className={classes.textField}
                value={name}
                variant="outlined"
                onChange={handleNameChange}
                margin="dense"
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            <TextField
              id="menu-room"
              label="Room"
              className={classes.textField}
              value={roomName}
              onChange={handleRoomNameChange}
              margin="dense"
              style={{ display: 'none' }}
            />
            <Button
              className={classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              disabled={isConnecting || !name || !roomName || isFetching}
            >
              Join Room
            </Button>
            {(isConnecting || isFetching) && (
              <CircularProgress className={classes.loadingSpinner} />
            )}
          </form>
        ) : (
          <Timer initialTime={0} formatValue={(value) => `${value < 10 ? `0${value}` : value}`}>
            <div className={classes.timer}>
              <Timer.Hours
                formatValue={(value) => `${value < 1 ? '' : value < 10 ? `0${value}:` : value}`}
              />
              <Timer.Minutes />:
              <Timer.Seconds />
            </div>
          </Timer>
          // <h3 className={classes.roomName}>{roomName}</h3>
        )}
        <div className={classes.rightButtonContainer}>
          <LocalAudioLevelIndicator />
          <ToggleThemeButton />
          <FlipCameraButton />
          <ToggleFullscreenButton />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
