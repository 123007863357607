import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import useVideoContext from '@hooks/useVideoContext';
import Controls from '@components/Controls';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '@state';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams } from 'react-router-dom';
import VideoTrack from '../VideoTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '50vw',
      marginLeft: 'calc(50vw - 25vw)',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100%',
        marginLeft: 'unset',
        marginTop: 'unset',
        marginBottom: 'unset',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '60vw',
        marginLeft: 'calc(50vw - 30vw)',
        marginTop: 'unset',
      },
    },
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      minHeight: '361px',
    },
    LocalVideoWrapper: {
      width: '100%',
      height: '100%',
      marginBottom: '-6px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 'unset',
      },
    },
    logo: {
      width: '50%',
      marginLeft: 'calc(50% - 25%)',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    helperText: {
      alignSelf: 'flex-end',
      marginBottom: '13px',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 110,
      },
    },
    submitButton: {
      marginLeft: theme.spacing(1),
      alignSelf: 'flex-end',
      marginBottom: '7px',
    },
    loadingSpinner: {
      width: '99.5%',
      marginBottom: '-18px',
      borderRadius: '4px',
    },
  }),
);

export default function LocalVideoPreview() {
  const { localTracks, isConnecting, connect } = useVideoContext();
  const classes = useStyles();

  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(
        null,
        '',
        window.encodeURI(`/room/${roomName}${window.location.search || ''}`),
      );
    }
    getToken(name, roomName).then((token) => connect(token));
  };

  const videoTrack = localTracks.find((track) => track.name === 'camera') as LocalVideoTrack;

  return (
    <div className={classes.wrapper}>
      <a href="https://www.sanasearch.ch/" target="_blank" rel="noreferrer noopener">
        <img
          src="https://www.swica.ch/-/media/swica/logos/gesundheitspartner/sanasearch-768x240.png?mh=1536&mw=1536&hash=9BA1242BB964D05C40ED64644351584C"
          alt="sanasearch"
          className={classes.logo}
        />
      </a>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.helperText}>Choose</div>
        <TextField
          id="menu-name"
          label="Your Name"
          className={classes.textField}
          variant="outlined"
          value={name}
          onChange={handleNameChange}
          margin="dense"
        />
        <div className={classes.helperText}> and audio, video settings</div>
        <Button
          className={classes.submitButton}
          type="submit"
          color="primary"
          variant="contained"
          disabled={isConnecting || !name || !roomName || isFetching}
        >
          Join Room
        </Button>
        {(isConnecting || isFetching) && <LinearProgress className={classes.loadingSpinner} />}
      </form>
      <Card raised className={classes.root}>
        <div className={classes.LocalVideoWrapper} data-testid="videoTrack">
          {videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null}
        </div>

        <Controls isLocal />
      </Card>
    </div>
  );
}
